@font-face {
    font-family: "Rubik Black";
    src: url("../fonts/Rubik/Rubik-Black.ttf") format("truetype");
}
@font-face {
    font-family: "Rubik Black Italic";
    src: url("../fonts/Rubik/Rubik-BlackItalic.ttf") format("truetype");
}
@font-face {
    font-family: "Rubik Bold";
    src: url("../fonts/Rubik/Rubik-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "Rubik Bold Italic";
    src: url("../fonts/Rubik/Rubik-BoldItalic.ttf") format("truetype");
}
@font-face {
    font-family: "Rubik Extra Bold";
    src: url("../fonts/Rubik/Rubik-ExtraBold.ttf") format("truetype");
}
@font-face {
    font-family: "Rubik Extra Bold Italic";
    src: url("../fonts/Rubik/Rubik-ExtraBoldItalic.ttf") format("truetype");
}
@font-face {
    font-family: "Rubik Italic";
    src: url("../fonts/Rubik/Rubik-Italic.ttf") format("truetype");
}
@font-face {
    font-family: "Rubik Light";
    src: url("../fonts/Rubik/Rubik-Light.ttf") format("truetype");
}
@font-face {
    font-family: "Rubik Light Italic";
    src: url("../fonts/Rubik/Rubik-LightItalic.ttf") format("truetype");
}
@font-face {
    font-family: "Rubik Medium";
    src: url("../fonts/Rubik/Rubik-Medium.ttf") format("truetype");
}
@font-face {
    font-family: "Rubik Medium Italic";
    src: url("../fonts/Rubik/Rubik-MediumItalic.ttf") format("truetype");
}
@font-face {
    font-family: "Rubik Regular";
    src: url("../fonts/Rubik/Rubik-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Rubik Semi Bold";
    src: url("../fonts/Rubik/Rubik-SemiBold.ttf") format("truetype");
}
@font-face {
    font-family: "Rubik Semi Bold Italic";
    src: url("../fonts/Rubik/Rubik-SemiBoldItalic.ttf") format("truetype");
}






